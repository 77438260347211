.Gadget {
  border: 1px solid #dee2e9;
  border-radius: 12px;
  background-color: white;
  margin-bottom: 24px;
}

.Gadget li {
  list-style: none;
}

.Highlight {
  border: 1px solid var(--primary-color-border-level1, #08a7bf);
}

.GadgetHeader {
  padding: 24px;
  display: flex;
  position: relative;
}

.GadgetTitle {
  display: flex;
  align-items: center;
  flex: 1;
  color: #4d4d4d;
  font-size: var(--font-size-2XLarge, 24px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--font-multi-line-height-2XLarge, 36px); /* 150% */
  letter-spacing: var(--font-letter-spacing-2XLarge, -0.96px);

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.NoHeaderContents {
  padding-top: 35px !important;
}

.NoHeaderSetting {
  position: absolute;
  top: 35px;
  right: 24px;
}

.HeaderSettingButton {
  background-color: transparent;
  opacity: 0;
  color: #aaa;
}
.Gadget:hover .HeaderSettingButton {
  opacity: 1;
}
.DarkmodeHeaderSettingButton {
  background-color: transparent;
  opacity: 0;
  color: #fff;
}
.DarkmodeHeaderSettingButton:hover {
  background: var(--palette-mint-mint40, #39b9cc);
  opacity: 0;
  color: #fff;
}

.GadgetContents {
  padding: 0px 24px 24px 24px;
  position: relative;
}

.DarkmodeGadgetContents {
  background: var(--palette-mint-mint30, #5ac4d4);
  border-radius: 12px;
  color: #fff;
}
.DarkmodeGadgetContents * {
  color: inherit;
}

.GadgetContents table tr th,
.GadgetContents table tr td {
  word-break: break-all;
  white-space: nowrap;
  text-align: center;
  height: 48px;
  vertical-align: middle;
  padding: 0px 8px;
}

.GadgetContents tr th {
  font-weight: 500;
}

/* Config */

.GadgetEdit {
  border-radius: 12px 12px 0px 0px;
  background-color: var(--color-bg-box, #f9fbfc);
}

.EditForm {
  padding: 0px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.FormList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.Divider {
  text-align: center;
  margin: 0px 24px;
  width: calc(100% - 48px);
  height: 1px;
  background-color: #f2f2f2;
}

.EmptyContainer {
  width: 100%;
  height: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.EmptyMessage {
  color: #aaa;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.32px;
}

/* Components */
.Select {
  padding: 4px 8px;
  width: 100%;
  height: 28px;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  background: #fff;
}

.Input {
  padding: 4px 8px;
  width: 100%;
  height: 28px;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  background: #fff;
}

.CheckBox {
  width: 100%;
  height: 20px;
  margin-bottom: 6px;
}

.Radio {
  display: flex;
  align-items: center;
  gap: 16px;
}

.RadioInput {
  width: 16px;
  height: 16px;
  border: 2px solid #c5c5c5;
  background-color: white;
  cursor: pointer;
}

.RadioInput:checked {
  border-color: #363636;
  accent-color: #363636;
}

.ConfigTitle {
  color: #4d4d4d;
  font-size: var(--font-size-Small, 14px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--font-size-Small, 14px); /* 100% */
  letter-spacing: var(--font-letter-spacing-Small, -0.28px);
  margin-bottom: 10px;
}

.Paginator {
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 16px 0;
}
